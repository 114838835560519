import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AppService } from './_services/app.service';
import { Site, Offer, Payment } from './_models';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'CasinoMoons';
  site: Site;
  offers: Offer[];
  payments: Payment[];
  error = '';
  success = '';

  constructor(
    @Inject(DOCUMENT) private dom,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService
  ) {}
  @HostBinding('class.navbar-open')
  isNavbarOpen: boolean = false;
  ngOnInit() {
    this.getSite();
    this.getPayments();
    const hostName = 'https://casino-moons.net';
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute);

        rt.data.subscribe((data) => {
          if (data.canonical) {
            this.updateCanonicalUrl(hostName + data.canonical);
          } else {
            this.updateCanonicalUrl(hostName + this.router.url);
          }
        });
      });
  }
  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  getSite(): void {
    this.appService.getSite().subscribe(
      (res: Site) => {
        this.site = res['website'];
        this.offers = res['offers'];
      },
      (err) => {
        this.error = err;
      }
    );
  }

  getPayments(): void {
    this.appService.getPayments().subscribe(
      (res: Payment[]) => {
        this.payments = res;
      },
      (err) => {
        this.error = err;
      }
    );
  }

  toggleNavbar(isNavbarOpen: boolean) {
    this.isNavbarOpen = isNavbarOpen;
  }

  goToPart(fragment: any) {
    this.router.navigateByUrl('#' + fragment );
    this.isNavbarOpen = false;
  }
}
