<div class="header">
  <div class="logo">
    <a
      routerLink=""
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="isNavbarOpen = false"
    >
      <img src="../assets/images/logo.svg" alt="Casino Moons" />
    </a>
  </div>
  <div class="nav" [class.navbar-open]="isNavbarOpen">
    <button
      class="navbar-toggle"
      (click)="toggleNavbar(!isNavbarOpen)"
      aria-label="Menu"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
    <nav>
      <a
        routerLink=""
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="isNavbarOpen = false"
        >Main</a
      >
      <a
        routerLink="/games"
        routerLinkActive="active"
        (click)="goToPart('games')"
        >Games</a
      >
      <a
        routerLink="/bonuses"
        routerLinkActive="active"
        (click)="goToPart('bonuses')"
        >Bonuses</a
      >
      <a
        routerLink="/mobile"
        routerLinkActive="active"
        (click)="goToPart('mobile')"
        >Mobile Version</a
      >
      <a
        routerLink="/signin"
        routerLinkActive="active"
        (click)="goToPart('signin')"
        >Sign In</a
      >
    </nav>
  </div>
</div>

<div class="main-section" [lazyLoad]="'../assets/images/background.webp'">
  <router-outlet></router-outlet>
</div>
