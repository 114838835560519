import { Component, OnInit } from '@angular/core';
import { Offer, Site } from '../_models';
import { AppService } from '../_services/app.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit {
  offers: Offer[];
  offer: Offer;
  site: Site;
  constructor(private appService: AppService, private route: ActivatedRoute) {
    const id = +this.route.snapshot.paramMap.get('id')!;
    this.appService.site.subscribe((x) => {
      this.site = x;
      if (x && id === 0) {
        window.top.location.replace(this.site.link);
      }
    });
    this.appService.offers.subscribe((x) => {
      this.offers = x;
      if (x && id > 0) {
        this.offer = this.offers.find((offer) => +offer.id === id)!;
        window.top.location.replace(this.offer.link);
      }
    });
  }

  ngOnInit(): void {}
}
