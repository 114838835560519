import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Site, Offer, Game, Payment, Provider } from '../_models';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AsyncSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  private siteSubject: BehaviorSubject<Site>;

  public site: Observable<Site>;
  games: Game[];
  providers: Provider[];
  private offersSubject: BehaviorSubject<Offer[]>;
  public offers: Observable<Offer[]>;
  private paymentsSubject: BehaviorSubject<Payment[]>;
  public payments: Observable<Payment[]>;

  constructor(private http: HttpClient) {
    this.siteSubject = new BehaviorSubject<Site>(null);
    this.site = this.siteSubject.asObservable();
    this.offersSubject = new BehaviorSubject<Offer[]>(null);
    this.offers = this.offersSubject.asObservable();
    this.paymentsSubject = new BehaviorSubject<Payment[]>(null);
    this.payments = this.paymentsSubject.asObservable();
  }
  public get offersData(): Offer[] {
    return this.offersSubject.value;
  }

  getSite() {
    return this.http
      .get(`${environment.apiUrl}/website/get-website/${environment.siteId}`)
      .pipe(
        map((res: any) => {
          this.siteSubject.next(res['website']);
          this.offersSubject.next(res['offers']);
          return res;
        }),
        catchError(this.handleError<Site>('getSite'))
      );
  }
  getPayments() {
    return this.http
      .get(`${environment.apiUrl}/website/get-payments/${environment.siteId}`)
      .pipe(
        map((res: any) => {
          this.paymentsSubject.next(res);
          return res;
        }),
        catchError(this.handleError<Payment[]>('getPayments', []))
      );
  }

  getGames(): Observable<Game[]> {
    return this.http
      .get(`${environment.apiUrl}/website/get-games/gambling`)
      .pipe(
        map((res: any) => {
          this.games = res;
          return this.games;
        }),
        catchError(this.handleError<Game[]>('getGames', []))
      );
  }

  getProviders(): Observable<Provider[]> {
    return this.http.get(`${environment.apiUrl}/website/get-providers/`).pipe(
      map((res: any) => {
        this.providers = res;
        return this.providers;
      }),
      catchError(this.handleError<Provider[]>('getProviders', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
