import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfferComponent } from './offer/offer.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    data: {
      canonical: '',
    },
  },
  {
    path: 'games',
    redirectTo: '',
    data: {
      canonical: '/games',
    },
  },
  {
    path: 'bonuses',
    redirectTo: '',
    data: {
      canonical: '/bonuses',
    },
  },
  {
    path: 'mobile',
    redirectTo: '',
    data: {
      canonical: '/mobile',
    },
  },
  {
    path: 'signin',
    redirectTo: '',
    data: {
      canonical: '/signin',
    },
  },
  { path: 'casino/:id', component: OfferComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
